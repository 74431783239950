@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  70% {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.inputContainer {
  position: relative;
  width: 98%;
  box-sizing: border-box;
  border-radius: 6px;
  transition: all 0.3s ease;
  border: 1px solid #ccc;

  &:focus-within {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    border-color: #007bff;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.inputField {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.inputLabel {
  font-size: 0.85rem;
  font-weight: 500;
  color: #6c757d;
}

.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1050;
  margin-top: 0.25rem;
}

.suggestion {
  padding: 0.5rem;
  font-size: 0.9rem;
  color: #495057;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #f8f9fa;
  }
}

.suggestion:active {
  background-color: #e9ecef;
}