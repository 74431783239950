:root {
  --main-font: "Open Sans", sans-serif;
  --color-grey: #5F7385;
  --color-white: #fff;
  --color-black: #000;
  --accent-text-color: var(--tg-theme-accent-text-color, #6ab2f2);
  --bg-color: var(--tg-theme-bg-color, #fff6e6f5);
  --button-color: var(--tg-theme-button-color, #008f0c);
  --button-text-color: var(--tg-theme-button-text-color, #ffffff);
  --destructive-text-color: var(--tg-theme-destructive-text-color, #ec3942);
  --header-bg-color: var(--tg-theme-header-bg-color, #17212b);
  --hint-color: var(--tg-theme-hint-color, #708499);
  --link-color: var(--tg-theme-link-color, #6ab3f3);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color, #708499);
  --section-bg-color: var(--tg-theme-section-bg-color, #17212b);
  --section-header-text-color: var(--tg-theme-section-header-text-color, #6ab3f3);
  --subtitle-text-color: var(--tg-theme-subtitle-text-color, #708499);
  --text-color: var(--tg-theme-text-color, #000000);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus, textarea:focus, select:focus {
  outline: none !important;
}
#root{
  height: 100%;
  width: 100%;
}
