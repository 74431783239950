.productPage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-right: 5px;
    background-color: #f8f8f8;
    height: 100%;
    width: calc(100% - 65px);
    right: 0;
    overflow: hidden;
}