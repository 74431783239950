.management-container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}


.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
}

.filters select {
    width: 45%;
    font-family: monospace;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.product-item {
    background-color: var(--secondary-bg-color);
    border: 1px solid var(--hint-color);
    padding: 5px;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    color: var(--text-color);
}
.product-list-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product-list-article{
    font-family: math;
    font-size: medium;
}
.product-list-name{
    max-width: 95%;
    overflow-wrap: break-word;
    font-family: monospace;
    font-size: 13px;

}
.product-item img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.product-details {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-color);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    color: var(--text-color);
}
.product-details img{
    max-width: 90%;
    height: auto;
}

.product-details input {
    width: 88%;
    margin-bottom: 10px;
}
.product-details select {
    width: 88%;
    margin-bottom: 10px;
}
.product-details textarea {
    margin-bottom: 10px;
    width: 88%;
    height: 400px;
}

.product-details button {
    margin-top: 10px;
    width: 80%;
    align-self: center;
}

.image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative; /* Для позиционирования стрелок */
}

.image-slider img {
    max-width: 100%;
    max-height: 300px; /* Ограничение высоты изображения */
    border-radius: 8px;
    margin: 0 10px; /* Отступы между изображениями */
}

.arrow-button {
    background: none;
    border: none;
    font-size: 24px; /* Размер стрелок */
    cursor: pointer;
    position: absolute; /* Позиционирование стрелок */
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color); /* Цвет стрелок */
}

.arrow-button:first-of-type {
    left: 10px; /* Отступ от левого края */
}

.arrow-button:last-of-type {
    right: 10px; /* Отступ от правого края */
}

.arrow-button:disabled {
    color: #ccc; /* Цвет для отключенной кнопки */
    cursor: not-allowed; /* Указатель для отключенной кнопки */
}

.image-slider button {
    width: 0;
    color: var(--button-text-color);
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.image-slider button:hover {
    background-color: rgba(224, 224, 224, 0.9); /* Полупрозрачный фон при наведении */
}

.image-slider button:disabled {
    background-color: #ccc; /* Цвет для отключенной кнопки */
    cursor: not-allowed; /* Указатель для отключенной кнопки */
}

.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px; /* Размер галочки */
    color: green; /* Цвет галочки */
    opacity: 1;
    transition: opacity 0.5s ease; /* Плавное исчезновение */
    z-index: 1000; /* Убедитесь, что галочка поверх других элементов */
}