.additionalInfo {
    background-color: #f9f9f9;
    display: flex;
    flex: 0.4;
    max-height: 33vh;

    .info {
        flex: 0.5;

        textarea {
            width: 98%;
            height: 80%;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 14px;
            scrollbar-width: none;
        }
    }

    .photos {
        flex: 0.5;
        max-height: 100%;

        .photoSection {
            display: flex;
            align-items: center;
            height: 100%;

            .mainPhotoContainer {
                position: relative;
                display: flex;
                flex: 0.8;
                align-items: center;
                justify-content: center;
                height: 100%;

                .arrow {
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    border: none;
                    padding: 8px;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 20px;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background: rgba(0, 0, 0, 0.7);
                    }
                }

                .mainPhoto {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 8px;
                    margin: 0 16px;
                    cursor: pointer;
                    transition: transform 0.3s;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }

            .photoGallery {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 10px;
                height: 100%;
                overflow-y: scroll;
                scrollbar-width: none;

                .galleryPhoto {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border: 2px solid transparent;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: border-color 0.3s;

                    &:hover {
                        border-color: #ff5f1f;
                    }

                    &.active {
                        border-color: #ff5f1f;
                    }
                }
            }
        }
    }

    .deleteButton {
        margin-top: 16px;
        background-color: #ca0216;
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #c82333;
        }
    }

    .fullscreenOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        .fullscreenPhoto {
            max-width: 90%;
            max-height: 90%;
            border-radius: 8px;
        }
    }
}