.login-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 60%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    margin-top: 10%;
    background-color: #4e4d4d;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-container .header {
    color: white;
    margin-bottom: 20px;
    font-size: 24px;
}

.login-container input {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #333;
    box-sizing: border-box;
}

.login-container .error {
    color: #FF5F1F;
    margin-bottom: 15px;
}

.login-container button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #FF5F1F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-container button:hover {
    background-color: #ff4500;
}