.orders-container {
    padding: 20px;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
}

.orders-table th, .orders-table td {
    border: 1px solid var(--hint-color);
    padding: 8px;
    text-align: left;
}

.orders-table th {
    background-color: var(--secondary-bg-color);
}

.orders-table tr:hover {
    background-color: var(--secondary-bg-color);
    cursor: pointer;
}

.order-details {
    width: 100%;
    margin-top: 10px;
}

.order-details th, .order-details td {
    border: 1px solid var(--hint-color);
    padding: 8px;
    text-align: left;
}

.order-details th {
    background-color: var(--secondary-bg-color);
}
