.statistics-container {
  padding: 10px;
}

.statistics-section {
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.statistics-list {
  list-style-type: none;
  padding: 0;
}

.statistics-list li {
  background-color: var(--section-bg-color);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.statistics-details {
  background-color: var(--section-bg-color);
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
}
