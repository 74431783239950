.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #837e7e;
    transition: all 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 300px;
    transform: translateX(-100%);
    opacity: 0;

    &.menu-expanded {
        transform: translateX(0);
        opacity: 1;
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
        background-color: rgbargba#837e7e;
    }

    &.menu-collapsed {
        width: 60px;
        transform: translateX(0);
        opacity: 0.8;
    }
}

.menu-toggle-icon {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1100;
    width: 40px;
    height: 40px;
    background-color: var(--button-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.9;
    transition: all 0.3s ease;

    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
}

.menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 15px;
    overflow-y: auto;
    flex-grow: 1;
}

.tileItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    margin: 0 10px;
    width: calc(100% - 20px);
    max-width: 250px;
    background-color: #FF4D00;
    opacity: 0.8;
    border-radius: 16px;
    text-decoration: none;
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    text-align: left;
    gap: 10px;

    &:hover {
        background-color: rgba(97, 97, 97, 0.2);
        transform: scale(1.03);
    }

    svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    &.disabled {
        background-color: #a0a0a0;
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
            transform: none;
        }
    }
}

// Стили для свернутого состояния меню
.side-menu.menu-collapsed {
    .tileItem {
        justify-content: center;
        padding: 10px;
        width: 40px;
        margin: 10px auto;

        span {
            display: none;
        }

        svg {
            margin-right: 0;
        }
    }
}

@media (max-width: 768px) {
    .side-menu {
        width: 100%;
        max-width: 300px;
    }

    .menu-toggle-icon {
        top: 10px;
        left: 10px;
        width: 35px;
        height: 35px;
    }

    .tileItem {
        padding: 12px;
        font-size: 14px;
    }
}