.App {
  overflow: hidden;
  height: 100%;
  position: relative;
  text-align: center;
  font-family: var(--main-font);
  justify-items: end;
}


.wrapper {
  max-width: 90%;
  margin: 0 auto;
}