.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    color: #333;
    text-align: center;

    .sticker {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        animation: bounce 1.5s infinite;

        .sticker-icon {
            width: 100%;
            height: 100%;
            color: #ff6b6b;
        }
    }

    .error-message {
        font-size: 1.5rem;
        font-weight: bold;
        color: #444;
    }
}

/* Анимация "прыжка" */
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(-8px);
    }
}