.tableContainer {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  flex: 0.6;
}

.tableContainer table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tableContainer th,
.tableContainer td {
  padding: 0.1em 0.2em;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tableContainer th {
  padding: 0;
  background-color: #f1f1f1;
  font-weight: normal;
  text-align: center;
}

.tableContainer tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tableContainer tr:hover {
  background-color: #f5f5f5;
}

.filterableHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.filterableHeader span {
  font-weight: bold;
}

.filterableHeader .customSelect {
  width: 100%;
}

.sortableHeader {
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    color: #ff5f1f;
  }
}


.id {
  width: 3%;
}

.article {
  width: 10%;
}

.categoryName {
  width: 15%;
}

.name {
  width: 25%;
}

.producerName {
  width: 10%;
}

.amount {
  width: 4%;
}

.price {
  width: 5%;
}

.color {
  width: 9%;
}

.size {
  width: 8%;
}

.goodCard {
  width: 9%;
}

.selectedRow {
  background-color: #ff5f1f;
}