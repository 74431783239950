.container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: #6b7280;
    width: 50%;
}

.selectBox {
    width: 100%;
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: #262626;
    color: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.selectBox:hover {
    background-color: #1f1f1f;
}

.selectedValue {
    font-size: 1rem;
}

.arrow {
    font-size: 1.25rem;
    color: #6b7280;
}

.dropdown {
    position: absolute;
    top: 100%;
    max-height: 40vh;
    overflow-y: scroll;
    left: 0;
    right: 0;
    background-color: #262626;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    z-index: 100;
    scrollbar-width: none;
}

.option {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.option:hover {
    background-color: #1f1f1f;
}

.option.selected {
    background-color: rgba(255, 95, 31, 0.2);
}

.option input {
    margin-right: 0.5rem;
    accent-color: #ff5f1f;
}

@media (max-width: 768px) {
    .selectBox {
        padding: 0.5rem;
    }

    .option {
        padding: 0.5rem;
    }
}