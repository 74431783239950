.loading {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.loadingImg {
    margin: auto;
}