.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 20px;
}

.columnManager {
    width: 20%;
}

.controls .searchInput {
    margin-left: 1rem;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.2s;
    width: 40%;
}

.controls .searchInput:focus {
    border-color: #ff5f1f;
    outline: none;
}

.controls .addButton {
    background-color: #ff5f1f;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.controls .addButton:hover {
    background-color: #e14e0e;
}

.pagination {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.paginationButton {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    background-color: #fff;
    cursor: pointer;

    &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-color: #f1f1f1;
    }
}

.paginationInfo {
    font-size: 0.875rem;
    color: #555;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pageInput {
    width: 3rem;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    padding: 0.25rem;
    font-size: 0.875rem;

    &:focus {
        outline: none;
        border-color: #FF5F1F;
        box-shadow: 0 0 0 2px rgba(255, 95, 31, 0.2);
    }
}

.saveButton {
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover:enabled {
        background-color: #FF5F1F;
        color: #FF5F1F;
    }
}

.modeFilter {
    width: 15%;
}

@media (max-width: 768px) {
    .controls {
        flex-direction: column;
        gap: 1rem;
    }

    .controls .searchInput {
        width: 100%;
        margin-left: 0;
    }

    .controls .addButton {
        width: 100%;
    }

    .columnManager {
        width: 100%;
    }
}