.empty-screen {
    width: 90%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.empty-text {
    font-size: 24px;
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin: 50px auto;
    height: 15%;
}

.textgif {
    width: 64px;
    height: 64px;
}

.keyboardLogo {
    width: 120px;
    height: 120px;
}